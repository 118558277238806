.pe-feature-styler {
  > div {
    input[type="range"] {
      appearance: none;
    }

    /* Chrome range style */
    input[type="range"]::-webkit-slider-runnable-track {
      height: 3px;
      background: #ddd;
      border: none;
      border-radius: 1px;
    }

    input[type="range"]::-webkit-slider-thumb {
      cursor: pointer;
      appearance: none;
      border: none;
      height: 14px;
      width: 14px;
      border-radius: 50%;
      background: black;
      margin-top: -5px;
    }

    /* Firefox range style */
    input[type="range"]::-moz-range-track {
      height: 3px;
      background: #ddd;
      border: none;
      border-radius: 1px;
    }

    input[type="range"]::-moz-range-thumb {
      cursor: pointer;
      appearance: none;
      border: none;
      height: 14px;
      width: 14px;
      border-radius: 50%;
      background: black;
      margin-top: -5px;
    }

    /* IE range style */
    input[type="range"]::-ms-track {
      height: 3px;
      background: transparent;
      border-color: transparent;
      color: transparent;
    }

    input[type="range"]::-ms-fill-lower {
      background: #ddd;
    }

    input[type="range"]::-ms-fill-upper {
      background: #ddd;
    }

    input[type="range"]::-ms-thumb {
      cursor: pointer;
      appearance: none;
      border: none;
      height: 14px;
      width: 14px;
      background: black;
      margin-top: -5px;
    }
  }
}

/* stylelint-disable selector-class-pattern */
@import "../../Globals";

.pe-stop-finder {
  position: relative;
  width: 239px;

  .pe-search__input {
    padding: 0 10px;
    width: calc(100% - 21px);
    height: 34px;
    border: 1px solid $gray;
    border-radius: 5px;
  }

  .pe-search__input::-ms-clear {
    display: none; /* For IE */
  }

  .pe-search__input--open {
    border-radius: 5px 5px 0 0;
  }

  .pe-search__input--focused,
  .pe-search__container--open .pe-search__input {
    border-color: $brand-primary;
  }

  .pe-search-button-submit,
  .pe-search-button-clear {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    padding: 0;
    border: 0;

    svg {
      padding: 0;
    }
  }

  .pe-search-button-submit {
    right: 0;
    background-color: #515151;
    height: 100%;
    top: 0;
    border-radius: 0 5px 5px 0;

    &:hover {
      background-color: $brand-primary;
    }

    svg {
      height: 80%;
      width: 80%;
      color: white !important;
      fill: white !important;
    }
  }

  .pe-search__container--open .pe-search-button-submit {
    border-radius: 0 5px 0 0;
  }

  .pe-search-button-clear {
    top: 1px;
    right: 35px;
    height: calc(100% - 2px);
    background-color: white;

    svg {
      height: 50%;
      width: 50%;
      color: #515151 !important;
      fill: #515151 !important;
    }
  }
}

/* stylelint-disable selector-class-pattern */
@import "./Globals";
@import "./components/Root/Root";

/* Components */
@import "./components/Bbox/Bbox";
@import "./components/FeatureStyler/FeatureStyler";
@import "./components/FeatureStylerPopup/FeatureStylerPopup";
@import "./components/FeatureStylerPopup/InputRange";
@import "./components/IconGenerator/IconGenerator";
@import "./components/PlanPreview/PlanPreview";
@import "./components/StopFinder/StopFinder";
@import "./components/Header/Header";
@import "https://fonts.googleapis.com/css?family=Lato:400,400i,700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap";
@import "https://db.onlinewebfonts.com/c/0e1927dde1e5ce1dcc975658afeb9e7c?family=FrutigerLTStd-Light";

* {
  /* global styles for FF */
  scrollbar-width: thin;
  scrollbar-color: #b1b1b1 transparent;

  @supports (-moz-appearance: none) {
    scrollbar-width: auto;
  }

  input[type="number"] {
    appearance: textfield; /* remove number input arrows */
  }
}

body {
  height: 100%;
  width: 100%;
  margin: 0;
  position: absolute;
  overflow: hidden;
  color: $gray-darkest;

  /**
   * These CSS are placed here and not in StopFinder.scss & Dropdown.scss because we use a
   * ReactDOM portal to attach the container to the body. This way we override
   * overflow: scroll in the route finder
   */
  .pe-search__suggestions-container--open {
    position: absolute;
    max-height: 300px;
    overflow: hidden;
    left: 0;
    top: 35px;
    border: 1px solid $brand-primary;
    border-top-color: $gray;
    border-radius: 0 0 5px 5px;
    background-color: white;
    width: calc(100% - 36px);
    z-index: $z-index-highest;
    overflow-y: auto;

    .pe-search__suggestions-list {
      list-style-type: none;
      padding: 0;
      margin: 0;

      .pe-search__suggestion {
        padding: 0 10px;
        color: inherit;
        height: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;

        &:hover {
          color: $brand-primary;
        }
      }

      .pe-search__suggestion--highlighted {
        background-color: $gray-light;

        p {
          color: $brand-primary;
        }
      }
    }
  }

  .pe-muiautosuggest.pe-search__suggestions-container--open {
    margin-top: -4px;
  }

  .pe-route-finder-track-dropdown-list {
    max-height: 250px;
    overflow: auto;
  }

  .tm-checkbox {
    margin-right: 10px;

    span {
      border-radius: 3px;

      &::after {
        border: solid $brand-primary;
        border-width: 0 2px 2px 0;
      }
    }
  }
}

html,
body,
input,
textarea,
select {
  font-family: Lato, sans-serif;
  font-size: 15px;
}

.pe-app,
.pe-root,
.rs-map {
  position: absolute;
  height: 100%;
  width: 100%;
}

.pe-root {
  overflow: hidden; /* IE 11 */
}

.tm-pointer {
  cursor: pointer;
}

:focus {
  outline: none;
}

input,
textarea,
select {
  border: none;
  border-bottom: 1px solid #eee;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

input[type="color"] {
  width: 44px; /* for ie11 */
}

select {
  cursor: pointer;
  background-color: white;
  width: auto;
  height: 35px;
  padding: 5px 10px 5px 5px;

  /* IE11 */
  &::-ms-expand {
    border: none;
    background: transparent;
    margin-left: 5px;
  }

  &:focus {
    box-shadow:
      inset 0 1px 1px rgb(0 0 0 / 7.5%),
      0 0 8px rgb(102 175 233 / 60%);
  }

  option {
    padding: 5px;
  }
}

input[type="range"] {
  padding: 0; /* IE11 */
}

.pe-button[disabled],
[role="button"][disabled],
button[disabled],
a[disabled] {
  cursor: not-allowed !important;
}

.pe-button {
  transition: background-color 0.5s ease;
  color: $gray-darkest;

  &:hover {
    background-color: $gray-light;
  }
}

/* .pe-button[disabled],
 [role='button'][disabled],
 div[disabled] {
   color: gray !important;
   cursor: not-allowed;

   &:hover {
     background-color: transparent;
   }
 } */

.ol-box {
  box-sizing: border-box;
  border-radius: 2px;
  border: 2px solid $brand-primary;
}

.pe-warning {
  color: $brand-primary;
  font-weight: normal;
}

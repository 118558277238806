/* Colors */
$gray-light: #eee;
$gray: #e6e6e6;
$gray-dark: #808080;
$gray-darkest: #414141;
$brand-primary: #1789c9;
$brand-primary-trans-01: rgb(235 0 0 / 10%);
$brand-primary-trans-02: rgb(235 0 0 / 20%);
$brand-primary-trans-03: rgb(235 0 0 / 30%);
$brand-primary-trans-05: rgb(235 0 0 / 50%);
$brand-primary-trans-06: rgb(235 0 0 / 60%);
$brand-primary-trans-07: rgb(235 0 0 / 70%);
$sbb-blue: #20347e;

/* Dimensions */
$sidebar-open-width: 270px;
$dialog-width: 350px;
$btn-sidebar-size-base: 50px;
$btn-popup-size-base: 40px;

/* Dialog position */
$top-position: 30px;
$right-position: 30px;

/* Font */
$font-family: lato, sans-serif;
$shadow-color: rgb(0 0 0 / 30%);
$box-shadow: 0 4px 4px 0 $shadow-color;

/* z-index levels */
$z-index-lowest: 1;
$z-index-low: 50;
$z-index-middle: 100;
$z-index-high: 200;
$z-index-highest: 800;

/* Export variables to be used in js files. */
:export {
  brandprimary: $brand-primary;
  brandprimarytrans01: $brand-primary-trans-01;
  brandprimarytrans02: $brand-primary-trans-02;
  brandprimarytrans03: $brand-primary-trans-03;
  brandprimarytrans05: $brand-primary-trans-05;
  brandprimarytrans06: $brand-primary-trans-06;
  brandprimarytrans07: $brand-primary-trans-07;
  graydarkest: $gray-darkest;
  gray: $gray;
}

/* Funktioniert nicht in IE oder EDGE nur Chrome und Safari */

@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white,  50%)) {
  /* For Google Chrome */
  ::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  ::-webkit-scrollbar-thumb {
    background: $foreground-color;
  }
}

@include scrollbars(3px, #808080);

@mixin load-font($fontFamily, $path) {
  @font-face {
    font-family: #{$fontFamily};
    src:
      url('#{$path}.woff') format('woff'),
      url('#{$path}.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
}

@include load-font(
  'SBBWeb-Roman',
  'https://cdn.app.sbb.ch/fonts/v1_6_subset/SBBWeb-Roman'
);

.pe-bbox {
  position: absolute;
  border: 3px solid red;
  transition: opacity 0.3s ease;
  pointer-events: none;

  > div {
    pointer-events: auto;
  }

  .pe-bbox-actions {
    display: flex;
    align-items: center;
    gap: 10px;
    pointer-events: auto;
    background: red;
    position: absolute;
    right: -3px;
    left: auto;
    bottom: -29px;
    width: auto;
    min-width: 50px;
    color: white;
    padding: 5px 10px;
    font-weight: bold;

    .pe-bbox-lock {
      height: 18px;
      width: 18px;
      cursor: pointer;
      padding: 0;
      border: 0;
      background-color: transparent;
      color: white;

      svg {
        height: 18px;
        width: 18px;
      }
    }

    .pe-bbox-size {
      display: flex;
      align-items: center;
      border-radius: 0 0 5px 5px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: calc(100% + 6px);
      box-sizing: border-box;

      svg {
        padding-right: 8px;
        font-size: 15px;
        cursor: move;
      }
    }
  }

  &.pe-user-is-drawing {
    opacity: 0.3;
    cursor: inherit;
  }

  &.pe-user-is-drawing * {
    pointer-events: none !important;
  }
}

.pe-bbox-buffer {
  pointer-events: none;
  position: absolute;
  inset: 80px 80px 25px 170px;
}

@import "../../Globals";

.ie {
  .tm-permalink-bt {
    display: none !important;
  }
}

.ole-dialog {
  display: none;
}

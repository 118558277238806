@import "../../Globals";

.pe-icon-generator {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > div {
    margin: 10px 0;
  }

  input {
    margin-top: 5px;
    text-align: center;
  }

  .pe-icon-generator-fill,
  .pe-icon-generator-icon-size,
  .pe-icon-generator-text,
  .pe-icon-generator-text-color {
    width: 45%;
  }

  .pe-icon-generator-text {
    margin: 20px 0;
    width: 100%;

    input {
      width: 100%;
      height: 30px;
    }
  }

  .pe-icon-generator-label {
    min-width: 120px;
  }
}

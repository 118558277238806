@import "../../Globals";

.pe-feature-styler-dialog {
  .pe-dialog-body {
    text-align: left;


    .disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    .pe-styler-label,
    .pe-select-label {
      color: #6a6a6a;
      width: 140px;
    }

    .pe-styler-text-colors,
    .pe-styler-text-bg-colors,
    .pe-styler-text-size,
    .pe-styler-text-align,
    .pe-styler-polygon-fill-pattern {
      width: 40%;
    }

    .pe-styler-text-size,
    .pe-styler-text-align {
      height: 60px;
      margin: 10px 0;

      input,
      select {
        margin-left: 10px;
      }
    }

    .pe-styler-text-align,
    .pe-styler-text-font,
    .pe-styler-polygon-fill-pattern {
      .pe-styler-button {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0.5px solid $gray;
        border-radius: 5px;
        height: 35px;
        width: 35px;
        margin: 5px 2px;
        font-weight: bold;
        font-size: 20px;

        &.active {
          background-color: $gray-light;
        }
      }
    }

    .pe-styler-polygon-fill-pattern-buttons,
    .pe-styler-text-align-buttons {
      display: flex;
      color: $gray-dark;
    }


    .pe-styler-polygon-fill-pattern-buttons {
      flex-wrap: wrap;
      width: 100px;

      .pe-styler-button {
        overflow: hidden;
        height: 24px;
        width: 24px;

        &.active {
          box-shadow: 0 0 2px 1px #00000029;
        }
      }
    }

    .pe-styler-polygon-fill-pattern-svg,
    .pe-styler-line-icon-svg {
      display: flex;
      align-items: center;
    }

    .pe-styler-line-icon-svg {
      svg {
        height: 15px;
      }
    }

    .pe-styler-polygon-fill-pattern-svg {
      svg {
        height: 40px;
        width: 40px;
      }
    }

    .pe-styler-text-font {
      align-self: center;
    }

    .pe-styler-text-area,
    .pe-styler-polygon-line-color,
    .pe-styler-polygon-fill-color,
    .pe-styler-text-bg-colors,
    .pe-styler-text-colors,
    .pe-styler-colors,
    .pe-styler-polygon-fill-pattern {
      margin: 10px 0;
    }

    .pe-styler-text-area {
      textarea {
        font-family: Lato, sans-serif;
        width: 240px;
        min-height: 15px;
      }
    }

    .pe-styler-polygon-line-color {
      width: 100%;
    }

    /* Icon categories */
    .pe-styler-icons {
      display: block;
      overflow-y: scroll;
      border: 1px solid #eee;
      height: 240px;
      margin-bottom: 10px;

      div {
        .pe-button {
          height: $btn-popup-size-base;
          width: $btn-popup-size-base;
          border: solid 1px $gray-light;
          padding: 2px;
          margin: 5px;
        }

        .pe-selected {
          border: solid 1px $brand-primary-trans-03;
          border-radius: 2px;
        }
      }
    }

    /* Dropdown */
    .pe-select-item {
      &.pe-styler-line-width,
      &.pe-styler-polygon-line-width {
        svg {
          path {
            stroke: $gray;
          }
        }
      }
    }

    .pe-select {
      margin: 10px 0;
      display: block;
      color: #6a6a6a;

      &.pe-styler-polygon-fill-pattern,
      &.pe-styler-polygon-line-width,
      &.pe-styler-polygon-line-type,
      &.pe-styler-line-type,
      &.pe-styler-line-width {
        width: 40%;
        overflow: visible;
      }

      .pe-styler-polygon-line-type-current-svg,
      .pe-styler-polygon-line-width-current-svg,
      .pe-styler-line-type-current-svg,
      .pe-styler-line-width-current-svg {
        height: 20px; /* for IE */
        width: 80px;
        display: flex;
        align-items: center;

        svg {
          width: 100%;
          height: 100%;
        }
      }

      .pe-styler-polygon-line-width-current-svg,
      .pe-styler-line-width-current-svg {
        svg {
          path {
            stroke: $gray;
          }
        }
      }

      .pe-styler-line-type-label,
      .pe-styler-line-width-label {
        width: 140px;
      }

      .pe-styler-icons-size-label {
        width: 100px;
      }

      .pe-icon-generator-icon-size-label {
        width: 130px;
      }

      .pe-styler-line-width-button,
      .pe-styler-line-type-button {
        width: 120px;
      }
    }
  }

  /* Footer */
  .pe-dialog-footer {
    justify-content: space-between;
    padding: 0;
    margin: 20px 0;
  }
}
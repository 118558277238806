@import "../../Globals";

.pe-header {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  width: 100%;
  box-shadow: 1px 2px 4px rgb(0 0 0 / 19.9%);
  z-index: $z-index-highest;

  .pe-plan-name-container {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 8px;
    visibility: hidden;
    opacity: 0;

    &.pe-visible {
      visibility: visible;
      opacity: 1;
      transition: opacity 1s linear;
    }

    .pe-plan-name-input {
      height: 40px;
      max-width: calc(100vw - 300px);

      input::-ms-clear {
        display: none;
      }

      input {
        font-size: 25px;
        color: #515151;
        border-radius: 5px;
        border: 1px solid transparent;
        padding: 0 5px;
        text-overflow: ellipsis;
        height: 100%;

        &:not([disabled]):hover {
          border-color: $gray;
        }

        &:not([disabled]):focus {
          border-color: $brand-primary;
        }
        
        &[disabled] {
          background-color: transparent;
        }
      }
    }

    .pe-header-saving {
      display: flex;
      align-items: center;
    }

    .pe-plan-name-error-message {
      display: flex;
      align-items: center;
    }
  }
}

/* stylelint-disable media-feature-name-no-unknown */
@import '../../Globals';

$north-arrow-size: 64px;
$north-arrow-size-fluid: clamp(50px, 10vw, 100px);
$north-arrow-margin-fluid: clamp(-20px, -2vw, -10px);
$copyright-font-size: 10px;
$copyright-font-size-fluid: clamp(8px, 1vw, 18px);
$logo-width: 64px;
$logo-height: 22px;
$logo-width-fluid: clamp(64px, 10vw, 120px);
$logo-height-fluid: clamp(22px, 10vw, 40px);
$padding: 5px;
$margin: clamp(5px, 1vw, 15px);
$scale-line-border: 2px;
$scale-line-font-size: 10px;
$scale-line-font-size-fluid: clamp(10px, 1vw, 18px);

@mixin device-scale-factor-css() {
  @for $i from 1 to 6 {
    &.pe-device-scale-factor-#{$i} {
      .pe-plans-bottom-left {
        bottom: 0;
        left: calc(#{$padding} / #{$i});

        & > div {
          margin: calc(#{$margin} / #{$i}) 0;
        }
      }

      .pe-logo {
        display: flex;
        bottom: calc(#{$padding} / #{$i});
        right: calc(#{$padding} / #{$i});
      }

      .rs-north-arrow {
        width: calc(#{$north-arrow-size-fluid} / #{$i});
        height: calc(#{$north-arrow-size-fluid} / #{$i});
      }

      .ol-scale-line-inner {
        font-size: calc(#{$copyright-font-size-fluid} / #{$i});
        border-width: calc(#{$scale-line-border} / #{$i});
      }

      .rs-copyright {
        font-size: calc(#{$copyright-font-size-fluid} / #{$i});
      }

      .pe-logo svg {
        width: calc(#{$logo-width-fluid} / #{$i});
        height: calc(#{$logo-height-fluid} / #{$i});
      }
    }
  }
}

.pe-plans-preview {
  .pe-plans-bottom-left {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column-reverse;

    & > div {
      display: block;
      position: relative;
      margin: 5px;

      &.pe-copyright-wrapper {
        left: 0;
        bottom: 0;
        display: flex !important;
        margin-right: 75px;
      }
    }

    .rs-north-arrow-simple {
      margin-left: -5px;
    }
  }

  .rs-north-arrow {
    width: $north-arrow-size;
    height: $north-arrow-size;
  }


  .ol-scale-line {
    left: 0;
    padding: 0;
    background: none;
    color: inherit;
    font-weight: bold;
    bottom: unset;
    position: relative;
  }

  .ol-scale-line-inner {
    margin: 0;
    color: inherit;
    border-color: currentcolor;
    border-width: $scale-line-border;
  }

  &.print {
    /* We scale the footer elements with device width in print mode  */
    
    /* @include device-scale-factor-css; */
    
    .rs-north-arrow {
      width: $north-arrow-size-fluid;
      height: $north-arrow-size-fluid;
    }

    .ol-scale-line-inner {
      font-size: $scale-line-font-size-fluid;
    }

    .rs-copyright {
      font-size: $copyright-font-size-fluid;
    }

    .pe-logo svg {
      width: $logo-width-fluid;
      height: $logo-height-fluid;
    }

    .rs-copyright a {
      text-decoration: none;
    }

    .pe-plans-bottom-left {
      bottom: 0;
      left: $margin;

      & > div {
        margin: $margin 0 0;
      }

      .rs-north-arrow-simple {
        margin-left: $north-arrow-margin-fluid;
      }
    }

    .pe-logo {
      display: flex;
      bottom: $margin;
      right: $margin;
    }

    button, 
    .rs-copyright + span,
    .rs-copyright + span + span {
      display: none;
    }
  }

  .pe-fit-extent,
  .pe-fit-extent:hover {
    background-color: #414141;
    color: white;
    position: absolute;
    bottom: 25px;
    right: 0;
    left: 0;
    width: 150px;
    height: 30px;
    margin: auto;
    z-index: 100;
    border: 2px solid white;
    opacity: 1;
    transition: opacity ease 0.3s;

    &[hidden] {
      opacity: 0;
    }
  }
}
